import * as Immutable from "immutable";

import * as CompanyContact from "application/models/management/companyContact";
import * as PayrollDataValue from "application/models/payroll/payrollDataValue";
import { RecordFactory } from "common/modules/recordFactory";
import { IPayrollDataChoiceDefinition } from "application/models/payrollDataChoiceDefinition";
import { SystemMode } from "application/models/management/system";

export enum CompanySetting {
    SE_Anställningsnummertyp = "se_anställningsnummertyp",
    FeatureTradeShifts = "feature_trade_shifts",
    FeaturePeriodReconciliation = "feature_period_reconciliation",
    FeatureWebClock = "feature_web_clock",
    FeatureRequestAbsence = "feature_request_absence",
    DisableExtraProfessions = "disable_extra_professions",
    RequireTimeReportApproval = "require_timereport_approval",
    FirstClockInDate = "first_clock_in_date",
    Esigning = "e_signing",
    EsigningType = "e_signing_type",
    EsigningTokenCredentialId = "e_signing_token_credentials_identifier",
    EsigningTokenCredentialsSecret = "e_signing_token_credentials_secret",
    EsigningClientCredentialId = "e_signing_client_credentials_identifier",
    EsigningClientCredentialSecret = "e_signing_client_credentials_secret",
    ReportApi = "report_api",
    FeatureAiForecast = "feature_ai_forecast",
    ShowOccupiedOtherStation = "show_occupied_otherStation",
    InactivateEmployee = "feature_inactivate_employee",
    FeatureSalaryPeriodStartDay = "feature_salary_period_start_day",
    FeatureShiftPayments = "feature_shift_payments",
    FeatureEmployeeCitizenship = "feature_employee_citizenship",
    FeatureBookkeepingExport = "feature_bookkeeping_export",
    BookkeepingExportAllowUnlockedMonth = "bookkeeping_export_allow_unlocked_month",
    BookkeepingShowSalaryVerification = "bookkeeping_show_salaryverification",
    CentralArticlesSystem = "centralArticlesSystem",
    CentralArticlesCheckoutSystem = "centralArticlesCheckoutSystem",
    UsePayrollContactInformation = "use_payroll_contact_information",
    PayrollContactInformation = "payroll_contact_information",
    UseFinancialContactInformationForAgi = "use_financial_contact_information_for_agi",
    MaxUsers = "max_users",
    FeatureDayBreak = "feature_daybreak",
    FeatureEmployeeAnnotation = "feature_employee_annotation",
    EsigningUseCustomDrugPolicy = "use_custom_drugpolicy",
    FeatureAutomaticSchedule = "feature_automatic_schedule",
    TwelveHoursWarning = "feature_twelve_hours_warning",
    YellowScheduleWarning = "feature_yellow_schedule_warning",
    AddAbsenceCreateApplicationShift = "feature_add_absence_create_application_shift",
    FeatureCollectumReport = "feature_collectum_report",
    CollectumReportAllItp1 = "collectum_report_all_itp1",
    AllowedIBANCountryCodes = "iban_allowed_country_codes",
}

export const DeluxeEsigningSettings = [
    CompanySetting.EsigningClientCredentialId,
    CompanySetting.EsigningClientCredentialSecret,
    CompanySetting.EsigningTokenCredentialId,
    CompanySetting.EsigningTokenCredentialsSecret,
] as const;

export interface IFeatureCanBeDeactivatedValues {
    featuresCanBeDeactivated: boolean;
    message: string;
}

export const Anställningsnummer_Custom_Unique_Per_Employment_ChoiceId = 2;
export const Anställningsnummer_Custom_Unique_Per_Employee_ChoiceId = 3;

export const EsigingDeluxeChoiceId = 3;

export function getEmploymentNumberType(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): PayrollDataValue.IPayrollDataValue {
    return companyInformationProperties && companyInformationProperties.get(CompanySetting.SE_Anställningsnummertyp);
}

export function employmentNumberIsUniquePerEmployment(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    const employmentNumberType = getEmploymentNumberType(companyInformationProperties);
    return employmentNumberType && employmentNumberType.choiceValue && employmentNumberType.choiceValue.choiceId === Anställningsnummer_Custom_Unique_Per_Employment_ChoiceId;
}

export function employmentNumberIsUniquePerEmployee(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    const employmentNumberType = getEmploymentNumberType(companyInformationProperties);
    return employmentNumberType && employmentNumberType.choiceValue && employmentNumberType.choiceValue.choiceId === Anställningsnummer_Custom_Unique_Per_Employee_ChoiceId;
}

export function employmentNumberIsCustom(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return employmentNumberIsUniquePerEmployee(companyInformationProperties) || employmentNumberIsUniquePerEmployment(companyInformationProperties);
}

export function getCompanyPropertiesSetting(
    properties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null,
    key: CompanySetting,
    valueType: PayrollDataValue.PayrollDataType = PayrollDataValue.PayrollDataType.Bool
) {
    const feature = properties && properties.get(<string>key);
    return feature ? feature.get(PayrollDataValue.getPayrollDataTypeFieldName(valueType)) : null;
}

export function featureTradeShifts(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureTradeShifts);
}

export function featureAutomaticSchedule(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureAutomaticSchedule);
}

export function featurePeriodReconciliation(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeaturePeriodReconciliation);
}

export function featureWebClock(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureWebClock);
}

export function featureRequestAbsence(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureRequestAbsence);
}

export function featureShowOccupiedOtherStation(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.ShowOccupiedOtherStation);
}

export function featureEsigning(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.Esigning);
}

export function featureReportAPI(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.ReportApi);
}

export function featureAiForecast(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureAiForecast);
}

export function featureInactivateEmployee(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.InactivateEmployee);
}

export function featureSalaryPeriodStartDay(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureSalaryPeriodStartDay);
}

export function featureShiftPayments(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureShiftPayments);
}

export function featureBookkeepignExport(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureBookkeepingExport);
}

export function featureDaybreak(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureDayBreak);
}

export function featureTwelveHoursWarning(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.TwelveHoursWarning);
}

export function featureYellowScheduleWarning(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.YellowScheduleWarning);
}

export function featureAddAbsenceCreateApplicationShift(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.AddAbsenceCreateApplicationShift);
}

export function featureEmployeeAnnotation(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureEmployeeAnnotation);
}

export function esigningUseCustomDrugPolicy(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.EsigningUseCustomDrugPolicy);
}

export function getEsigningType(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>) {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.EsigningType, PayrollDataValue.PayrollDataType.Choice);
}

export function featureEmployeeCitizenship(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureEmployeeCitizenship);
}

export function featureCollectumReport(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FeatureCollectumReport);
}

export function collectumAllItp1(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.CollectumReportAllItp1);
}

export function isDeluxeEsigningType(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    if (!companyInformationProperties) return false;
    const esigningType: IPayrollDataChoiceDefinition = getEsigningType(companyInformationProperties);
    return Boolean(esigningType && esigningType.choiceId === EsigingDeluxeChoiceId);
}

export function disabledExtraProfessions(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.DisableExtraProfessions);
}

export function requireTimeReportApproval(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.RequireTimeReportApproval);
}

export function firstClockInDate(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): IMoment {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.FirstClockInDate, PayrollDataValue.PayrollDataType.Date);
}

export function bookkeepingExportAllowUnlockedMonth(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.BookkeepingExportAllowUnlockedMonth);
}

export function bookkeepingShowSalaryVerification(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue> | null): boolean {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.BookkeepingShowSalaryVerification);
}

export function getAllowedIBANCountryCodes(companyInformationProperties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>): string {
    return getCompanyPropertiesSetting(companyInformationProperties, CompanySetting.AllowedIBANCountryCodes, PayrollDataValue.PayrollDataType.String);
}

// ----------- CompanyInformation -----------
export interface ICompanyInformationValues {
    id: number;
    companyName: string;
    organizationCode: string;
    street: string;
    zipCode: string;
    city: string;
    businessName: string;
    phone: string;
    contact: string;
    invoiceEmail: string;
    system: string;
    countryCode: string;
    currency: string;
    startDate: string;
    logotype: string;
    logotypeUrl: string;
    filename: string;
    migrationId: string;
    cloneOf: string;
    contacts: Immutable.List<CompanyContact.ICompanyContact>;
    properties: { [key: string]: PayrollDataValue.IPayrollDataValue } | Immutable.Map<string, PayrollDataValue.IPayrollDataValue>;
    companyCorporateGroupNumber: string;
    mode: SystemMode;
    welcomeMailText: string;
    editWelcomeMailBeforeSend: boolean;
}

export interface ICompanyInformationRecord extends CompanyInformation {}

const CompanyInformationRecord = RecordFactory<ICompanyInformationValues>({
    id: 0,
    companyName: "",
    organizationCode: "",
    street: "",
    zipCode: "",
    city: "",
    businessName: "",
    phone: "",
    contact: "",
    invoiceEmail: "",
    system: "",
    countryCode: "",
    currency: "",
    startDate: "",
    logotype: "",
    logotypeUrl: "",
    filename: "",
    migrationId: "",
    cloneOf: "",
    contacts: Immutable.List<CompanyContact.ICompanyContact>(),
    properties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>(),
    mode: SystemMode.Undefined,
    companyCorporateGroupNumber: "",
    welcomeMailText: "",
    editWelcomeMailBeforeSend: false,
});

export class CompanyInformation extends CompanyInformationRecord implements ICompanyInformationValues {
    properties: Immutable.Map<string, PayrollDataValue.IPayrollDataValue>;
    constructor(values: Readonly<Partial<ICompanyInformationValues>>) {
        let modifiedValues: Partial<ICompanyInformationValues> = {};
        if (values.properties && !Immutable.Iterable.isIterable(values.properties)) {
            let properties = Immutable.Map<string, PayrollDataValue.IPayrollDataValue>();
            for (let name in values.properties) {
                const props = (<{ [key: string]: PayrollDataValue.IPayrollDataValue }>values.properties)[name];
                properties = properties.set(name, new PayrollDataValue.PayrollDataValue(props));
            }
            modifiedValues.properties = properties;
        }

        if (values.contacts && !Immutable.Iterable.isIterable(values.contacts)) {
            modifiedValues.contacts = Immutable.List<CompanyContact.ICompanyContact>(values.contacts.map((c) => CompanyContact.CompanyContact(c)));
        }
        super({ ...values, ...modifiedValues });
    }
}
